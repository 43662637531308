





































import { Component, Prop, Vue } from "vue-property-decorator";
import { FileManager } from "uknow-react-components";
import api from "@/api";
import store from "../../store";

@Component({
  components: { FileManager },
})
export default class ExternalShare extends Vue {
  get vourusUrl() {
    return store.state.vourusUrl;
  }
}
